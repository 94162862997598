import React, { useState } from "react";
import logo from '../../../src/unilorin2.jpeg';
import { useNavigate } from "react-router-dom";





export const AdLogin = () => {
const [staff_id, setStaff_id] = useState("")
const [password, setPassword] = useState("")
const navigate = useNavigate()


const data ={
   "staff_d":"900",
   "password":"bobly",
}


const handLogin = ()=>{
   if(staff_id != data.staff_d || password != data.password){
      alert("kindly provide an admin details")
      return 
   }
   navigate("/admin")
}

   return (
      <div className="flex h-screen justify-center items-center bg-gray-200">
         <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 w-96">
            <img src={logo} className="h-24 mb-4" alt="Logo" />
            <h1>Login As Admin</h1>
            <input 
            value={staff_id}
            onChange={(value) =>setStaff_id(value.target.value)}
               type="text" 
               placeholder="Your Staff_id" 
               className="p-3 m-2 bg-gray-100 w-full rounded-md border border-blue-950 focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
            <input 
            onChange={(value) =>setPassword(value.target.value)}
               type="password" 
               placeholder="your password" 
               className="p-3 m-2 bg-gray-100 w-full rounded-md border border-blue-950 focus:outline-none focus:ring-2 focus:ring-blue-500" 
            />
            <button onClick={handLogin} className="bg-blue-950 text-white p-3  w-full rounded-md hover:bg-blue-700 transition duration-200">
               Login 
            </button>
         </div>
        
      </div>
   );
};
