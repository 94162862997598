import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

export const ViewStd = () => {
  const navigate = useNavigate();
  const [std, setStd] = useState([]);
  const [loading, setLoading] = useState(true);




  const FechStd = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://researchgns.com.ng//fetch-std", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resdata = await response.json();
      if (!response.ok) {
        alert("check network connections");
        return;
      }
      setStd(resdata.data);
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    FechStd();
  }, []);

  const headers = ["Matric", "Email", "Falculty/Department"];

  return (
    <div className=" p-5 m-5 flex flex-col">
      {std.length === 0 ? (
        <FaSpinner className="animate-spin" />
      ) : (
        <table className="min-w-full table-auto">
          <thead className="bg-white border-b  rounded-lg border-gray-300 shadow-lg">
            <tr>
              {headers.map((item, index) => (
                <th key={index} className="p-2 m-1  text-left ">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="p-1 m-1">
            {std?.map((item, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                } p-2 m-1`}
              >
                <td className="text-xl text-gray-700">{item.matric_number}</td>
                <td className="text-xl text-black">{item?.email}</td>
                <td className="text-xl text-gray-900">{item.falculty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
