import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ApealStd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);  // Use empty array to store the list of appeals

  // Fetch data function
  const Apeal = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://researchgns.com.ng/admin/get-complain", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });

      // Check if the response is OK before trying to parse it
      if (response.ok) {
        const result = await response.json();
        setData(result.data); // Set the response data to the state
  
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    Apeal();
  }, []);


  return (
    <div className="p-5 m-5 flex flex-col">
      <div className="bg-red-300 text-white p-5 w-fit rounded-lg justify-center self-center">
        Please View attached reasons to accept or decline
      </div>

      <div className="mb-5 bg-white p-5 m-5">
        {loading ? (
          <div>Loading...</div>
        ) : (
          data?.map((complaint, index) => (
            <div key={index}>
              <li>
                {complaint.name} {complaint.matric_number} requested re-opening of{" "}
                week {complaint.post_id} <span className="text-gray-500">reason attached</span>{" "}
                <button
                  onClick={() => navigate("/admin/view",{state:complaint.id})}
                  className="bg-blue-950 text-white pr-1 pl-1 m-1 rounded-lg"
                >
                  View
                </button>
              </li>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
