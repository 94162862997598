import React from "react";
import { FaSpinner } from "react-icons/fa6";

export default function Modal({ on, controller, handlesumit, sumitControl}) {
    
    return (
        <>
            {on && (
                <div style={styles.modalBackdrop}>
                    <div style={styles.modalContainer}>
                        <h2>Do you want to submit your answers? you can take the exam again  your score will not recorded after first submit. </h2>
                        <div style={styles.buttonContainer}>
                            <button
                                style={styles.button}
                                onClick={controller}
                            >
                              No
                            </button>
                            
                            <button
                                style={styles.button}
                                onClick={handlesumit}
                            >
                                {sumitControl?
                                <FaSpinner className="animate-spin" />
                                :
                                'Yes'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

// Inline styles for the modal
const styles = {
    modalBackdrop: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalContainer: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        textAlign: "center",
        width: "300px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    button: {
        padding: "10px 20px",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
    },
};
