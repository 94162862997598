import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Importing images for each research group (example)
import Prof from '../prof.jpeg'

import logo from "../../src/university.jpg";
import "./landing.css";

export const LandingPage = () => {
  const [fade, setFade] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setFade(true);
    }, 300); // Start fade-in after 300ms
    return () => clearTimeout(timer);
  }, []);
  
  const researchGroups = [
    { title: "Prof L.A YAHAYA", image: Prof },
    { title: "Prof. Medinat F. Salman", image: Prof },
    { title: "Dr. M. B. Bello  (Virtual Attendee)", image: Prof },
    { title: "Prof. Olufunmilola Dominic", image: Prof },
    { title: "Prof. M. Ogunjimi", image: Prof },
    { title: "Dr. A. A. Atolagbe", image: Prof },
    { title: "Dr. Foluke Bolu-Steve", image: Prof },
    { title: "Dr. D. J. Kayode", image: Prof },
    { title: "Dr. Hafsat I. Alabi", image: Prof },
    { title: "Dr. M.S. Abdullah", image: Prof }
  ]
  return (
    <div className="mt-10 h-screen relative rounded-lg ml-4">
    {/* Background */}
    <div
      className="absolute inset-0"
      style={{
        backgroundImage: `url(${logo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: 0.7, // Adjust this value for opacity
      }}
    />
  
    {/* Foreground Content */}
    <div className="relative z-10 flex flex-col h-full animate-fade-in">
      {/* Welcome Message */}
      <center>
        <div className="mt-32 bg-white w-fit p-6 rounded-lg shadow-lg">
          <h3 className="text-blue-950 text-4xl font-light m-1 p-1">
            Welcome GNS RESEARCH TEAM
          </h3>
          <p className="text-blue-900 font-semibold text-lg">
            Improving Assessment Quality: This is to aid and improve GNS practice
            in the university.
          </p>
          <button
            onClick={() => navigate("/login")}
            className="shadow-lg mt-4 py-2 px-6 rounded-xl text-white bg-blue-600 hover:bg-blue-700 text-2xl transition duration-200"
          >
            Click Here to Get Started
          </button>
        </div>
      </center>
  
      {/* Research Groups Title */}
      <center>
        <div className="text-blue-950 p-3 mt-10 bg-white text-3xl font-semibold w-fit rounded-lg shadow-md">
          LIST OF RESEARCH GROUPS
        </div>
      </center>
  
      {/* Grid Layout for Research Groups */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 p-6 m-4">
        {researchGroups.map((item, index) => (
          <div
            key={index}
            className="bg-white p-4 shadow-lg rounded-lg hover:shadow-xl transition duration-200"
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-20 h-20 rounded-full mx-auto"
            />
            <h2 className="text-center mt-4 text-gray-700 font-medium text-lg">
              {item.title}
            </h2>
          </div>
        ))}
      </div>
    </div>
  </div>
  
  );
};
