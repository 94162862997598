import React from 'react';
import logo from '../../src/unilorin2.jpeg';
import { useNavigate } from 'react-router-dom';
import { FaSignInAlt, FaFileAlt, FaUsers, FaBullseye, FaUser, FaOptinMonster} from "react-icons/fa";
import { Outlet } from 'react-router-dom';




function Dashboard() {
  const navigate = useNavigate()
  return (
    <div className="bg-gray-100 min-h-screenp-3 m-4">

   <div className="shadow-lg bg-white  p-5 fixed left-0 right-0 top-5 rounded-lg  z-10">
   <img src={logo} className='h-16 w-16' />
               <ul className="flex flex-row items-center justify-around">
                  <li className="flex flex-row items-center m-2 p-2 hover:underline">
                     <FaUser className="text-blue-500" />
                     <button>Contact Us</button>
                  </li>
                  <li onClick={()=>navigate("/instruc")} className="flex flex-row items-center m-2 p-2 hover:underline">
                     <FaOptinMonster className="text-blue-500" /> 
                     Learn/Instructions
                  </li>
                  <li className="flex flex-row items-center m-2 p-2 hover:underline">
                     <FaSignInAlt className="text-blue-500" />
                     <button onClick={() => navigate("/login")}>Get started</button>
                  </li>
               </ul>
            </div>

      {/* Outlet for nested routes */}
      <div className="pt-32 pb-0"> {/* Adjust padding to avoid overlap with fixed navbar */}
        <Outlet />
      </div>

      <div className='bg-black text-center rounded-md text-white pt-0 p-10'>
        <h2 className='text-2xl text-white'>Please make sure you read the instructions sent out</h2>
        <p className='text-1xl text-white'>Enhure you are a students with a valid matric number</p>
        <p>
        </p>
      </div>
    </div>
  );
}

export default Dashboard;
