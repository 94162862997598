import logo from './unilorin2.jpeg';
import './App.css';
import Dashboard from './component/dashboard';
import { LandingPage } from './component/landing';
import { Login } from './component/auth';
import { StudentDashboard } from './component/auth/studentsComponet/dashboard';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './component/auth/studentsComponet/homepage';
import { Apeal } from './component/auth/studentsComponet/apeal';
import FlowChart from './component/auth/studentsComponet/assement';
import {AdminDashboard} from './component/admin/dashboardadmin';
import { AdLogin } from './component/admin/LoginAdmin';
import { Notfound } from './component/notfound';
import { AdminHome } from './component/admincomp/adminHome';
import { ApealStd } from './component/admincomp/apealstud';
import { AdminView } from './component/admincomp/AdminView';
import { AdminUpload } from './component/complete';
import { ViewStd } from './component/admin/allstd';
import { ReadOrPlay } from './component/class/playorread';
import TextPage from './component/class/test';
import ViewStdPerson from './component/admincomp/ViewStudent';
import ViewAllStd from './component/admin/viewAll';
import RestOpen from './component/class/instructions';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='test' element={<TextPage />} />
<Route path='*' element={<Notfound />} />
{/* admin */}

  <Route path='admin' element={<AdminDashboard />} >
  <Route path='' element ={<AdminHome />} />
  <Route path='std' element ={<ViewStd />} />
  <Route path='upload' element ={<AdminUpload />} />
  <Route path='view' element ={<AdminView />} />
  <Route path='allstd' element ={<ViewAllStd />} />
  
  <Route path="viewstd" element={<ViewStdPerson />} />
  <Route path='apeal' element={<ApealStd />} />
</Route>



<Route path="reopl" element={<ReadOrPlay />} />

<Route path='loginadmin' element={<AdLogin />} />
        <Route path='dashboard' element={<StudentDashboard />}>
      
        <Route path='' element={<HomePage/>} />
        <Route path='apeal' element={<Apeal/>} />
        <Route path='flow' element={<FlowChart/>} />
        </Route>
      <Route path='login' element={<Login />}/>
        <Route path='' element={<Dashboard />}>
         <Route path='' element={<LandingPage />} />
         <Route path='instruc' element={<RestOpen />} />
        </Route>
      </Routes>
    </Router>

  );
}

export default App;
