import React, { useState, useEffect } from "react";
import { FaFileAlt, FaPlayCircle } from "react-icons/fa";
import TextToSpeech from "../../../std/voice";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds
  const [loading, setLoading] = useState(false);
  const [task, setData] = useState([]);
  const navigate = useNavigate()


  const FechTASK = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://researchgns.com.ng/fetch-tasks", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resdata = await response.json();
      if (!response.ok) {
        alert("check network connections");
        return;
      }
      setData(resdata.data);
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    FechTASK();
  }, []);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId); // Cleanup on unmount
    }
  }, [timeLeft]);

  const handlePlayClick = () => {
    console.log("Play button clicked");
  };

  const handleReadClick = () => {
    console.log("Read button clicked");
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className="flex flex-col items-center bg-white rounded-lg shadow-lg justify-center min-h-screen p-4 md:p-8">
    {/* Task List */}
    <h1 className="text-2xl md:text-3xl font-bold mb-6">
      {task.map((item, index) => (
        <div key={index}>
          {item.is_active ? (
            <div className="flex flex-col items-start mb-6">
              <h2 className="text-xl md:text-2xl">
                Week {item.week}{" "}
                <span className="text-lg font-normal block">
                  Topic:{" "}
                  <i>
                    {item?.post}{" "}
                    <span className="text-sm text-red-500">(15 min read)</span>
                  </i>
                </span>
              </h2>
              <button
                onClick={() => navigate("/reopl", { state: item?.id })}
                className="bg-blue-950 p-3 mt-4 rounded-lg text-white hover:bg-blue-700 transition duration-300"
              >
                Take the Class
              </button>
            </div>
          ) : null}
        </div>
      ))}
    </h1>
  
    {/* Instructions Section */}
    <div className="absolute top-10 right-4 md:right-20 text-center md:text-left">
      <h3 className="text-xs md:text-sm text-red-500">
        At elapsed time, you will have to Appeal
      </h3>
      <h2 className="text-lg md:text-xl font-semibold mt-2">
        Get started: follow the instructions carefully.
      </h2>
    </div>
  
    {/* Additional Information */}
    <div className="mt-6 text-center space-x-4">
      <p className="text-lg md:text-xl">
        There is an option to either play or read more about the topic!
      </p>
      <p className="text-red-500 text-sm md:text-base mt-4">
        Do ensure to take this class before another article is published.
      </p>
    </div>
  </div>
  
  );
};
