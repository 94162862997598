import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";

export const Apeal = () => {
  const [reason, setReason] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [id, setId] = useState(null)
  const student_id = localStorage.getItem("id");

  
  const handleSubmit = async () => {
    setLoading(true);
    
    try {
      const response = await fetch("https://researchgns.com.ng/complain", {
        method: "POST",
        headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
        body: JSON.stringify({
         student_id:student_id,
         post_id:id,
         reason:reason
       })

      });
      if (!response.ok) {
        alert("Please try again later.");
        return;
      }
      const data = await response.json();
      
      alert(data?.message);
      setSubmitted(true);  
    } catch (error) {
      alert("An error occurred. Please try again.");

    } finally {
      setLoading(false);
    }
  };


console.log(student_id,reason, id)



  const fetchTasks = async () => {
    try {
      const response = await fetch("https://researchgns.com.ng/fetch-tasks", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resdata = await response.json();
      if (!response.ok) {
        alert("Check network connection.");
        return;
      }
      setData(resdata.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mb-4">Missed Class Form</h1>
        <label htmlFor="reason" className="block text-lg mb-2">
          Please provide a reason for missing your class:
        </label>

        <label className="text-red-500 text-sm text-center">Pick from the above</label>
        <select
          onChange={(e) => setId(e.target.value)} // Update reason with selected option
          value={reason}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {data?.map((item, index) => (
            <option key={index} value={item.id}>
              {item.tags}
            </option>
          ))}
        </select>

        <textarea
          id="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows="4"
          cols="10"
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter your reason for missing this class here. e.g (I was sick during this period, just getting better)"
          required
        />

        <p>
          For more proof, you can attach a file or letter of appeal{" "}
          <span className="text-gray-300 text-sm">Optional</span>
        </p>
        <input
          className="bg-blue-950 text-white w-fit rounded-lg m-3 p-2"
          type="file"
        />
        <button
        onClick={handleSubmit}
          type="submit"
          className="mt-4 bg-blue-950 w-full text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-200"
        >
         {loading?  <FaSpinner className="animate-spin" />
         :"Submit"
      }
         
        </button>
      {submitted && (
        <p className="mt-4 text-green-600">
          Thank you! Your reason has been submitted.
        </p>
      )}
    </div>
  );
};
