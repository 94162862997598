import React, { useState } from "react";
import { FaBars, FaHome, FaClipboardList, FaBullhorn, FaChartBar, FaEnvelope } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import logo from '../../../src/unilorin2.jpeg';

export const AdminDashboard = () => {
  const [openToggle, setToggle] = useState(false);

  const onClickToggle = () => {
    setToggle(!openToggle);
  };

  return (
    <>
      <div className="flex h-screen bg-gray-100">
        <div
          className={`fixed top-0 left-0 z-10 h-screen w-2/3 lg:w-64 bg-white text-gray-950 p-4 flex flex-col transition-transform duration-300 ${
            openToggle ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0`}
        >
          <div className="flex items-center mb-6">
            <img
              src={logo}
              alt="User Avatar"
              className="w-12 h-12 rounded-full mr-3 border-2 border-gray-500"
            />
            <div>
              <h2 className="text-xl font-semibold">Admin</h2>
              <p className="text-gray-400 text-sm">support@unilorin.ng</p>
            </div>
          </div>
          <h2 className="text-2xl font-bold mb-4">Gns Portal 1.0</h2>
          <h2 className="text-xl font-bold mb-6">Your Dashboard</h2>

          <input
            type="text"
            placeholder="Search..."
            className="mb-4 w-full px-3 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
          />
          <ul className="flex-1 space-y-3">
            <li>
              <Link onClick={onClickToggle} to="" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
                <FaHome className="mr-2" /> Home
              </Link>
            </li>
            <li>
              <Link onClick={onClickToggle} to="apeal" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
                <FaClipboardList className="mr-2" /> Apeal Students
              </Link>
            </li>
            <li>
              <Link onClick={onClickToggle} to="allstd" className="flex items-center p-3 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
                <FaBullhorn className="mr-2" /> See all students Scores
              </Link>
            </li>
          </ul>
        </div>

        <div className="flex-1 p-6 lg:ml-64 flex flex-col">
          <FaBars
            onClick={onClickToggle}
            className="lg:hidden text-white bg-blue-950 right-0 cursor-pointer mb-4"
          />
          <div className="flex-1 overflow-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
