import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const AdminHome = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [std, setStd] = useState([])



  const FechStd = async () => {
   try {
     setLoading(true);
     const response = await fetch("http://researchgns.com.ng//fetch-std", {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     });

     const resdata = await response.json();
     if (!response.ok) {
       alert("check network connections");
       return;
     }
     setStd(resdata.data)
   } catch {
     setLoading(false);
   } finally {
     setLoading(false);
   }
 };
 React.useEffect(() => {
   FechStd();
 }, []);





  const FechTASK = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://researchgns.com.ng/fetch-tasks", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resdata = await response.json();
      if (!response.ok) {
        alert("check network connections");
        return;
      }
      setData(resdata.data)
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    FechTASK();
  }, []);



  const navigate = useNavigate();

  return (
    <div className=" p-5 m-5 flex flex-col">

      {/* loading task */}
      <div className="mb-5 bg-white p-5 m-5">
        <div>Total Number of student {std.length}</div>
        <h1 className="text-gray-950 text-sm">Recent Tasks</h1>
        {data.length ===0?    <div>
          <li>
           please Add task
          </li>
        </div>:
        <div>
         {data?.map((item, index)=>(
           <li>
           {item?.tags}{" "}
           <span className="text-gray-500">Week {item?.week}</span>{" "}
           <button className=" bg-blue-950 text-white pr-1 pl-1 m-1 rounded-lg">
             View
           </button>
         </li>
         ))}
        </div>
}
      </div>
      <div className="flex flex-row text-center items-center justify-center shadow-lg bg-white p-2 m-2">
        <p>Add More Procedure for student click</p>{" "}
        <button
          onClick={() => navigate("upload")}
          className="bg-blue-950 pl-3 pr-3 ml-3 rounded-lg text-white"
        >
          Here
        </button>
      </div>
      <div className="m-4 p-4 text-center">Quick Operations </div>
      <div className="m-5 bg-white p-5 flex flex-row justify-center">
        <div>
          <button  onClick={()=>navigate('viewstd')} className="bg-blue-950 text-white p-3 m-3 rounded-lg">
            Check For a particular student
          </button>
        </div>

        <div>
          <button onClick={()=>navigate("std")} className="bg-blue-950 text-white p-3 m-3 rounded-lg">
            Fetch All students
          </button>
        </div>
      </div>
    </div>
  );
};
