import React, { useEffect, useState, useRef } from "react";
import { FaSpinner } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import TextToSpeech from "../std/voice";
import { useNavigate } from "react-router-dom";

export const ReadOrPlay = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [read, setRead] = useState("");
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(null);
  const timerRef = useRef(null);


  
  const Fetchtasks = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://researchgns.com.ng/fetch-related-task?id=${location?.state}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const datafetched = await response.json();
      setTimeLeft(datafetched?.data?.time || 0);
      setData(datafetched?.data || []);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Fetchtasks();
  }, []);

  useEffect(() => {
    if (timeLeft > 0) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("timeLeft", newTime);
          if (newTime <= 0) {
            clearInterval(timerRef.current);
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timerRef.current);
    }
  }, [timeLeft]);

  const Read = (data) => {
    setRead(data);
  };



  return (
    <div>
    {loading || !data ? (
      <center>
        <FaSpinner className="animate-spin text-4xl text-blue-600" />
        <p>Please wait ....</p>
      </center>
    ) : (
      <div className="px-4 md:px-8">
        <center className="bg-white shadow-md rounded-lg p-4">
          <h3 className="text-gray-950 text-2xl md:text-3xl p-1 m-1 capitalize mt-6 mb-6">
            Week {data?.[0]?.week || "No Week Available"}
          </h3>
          <p>{data?.[0]?.tags}</p>
          <h2 className="text-xl md:text-2xl text-gray-950 p-1 m-1 capitalize mt-6 mb-6">
            {data?.[0]?.post || "No Post Available"}
          </h2>
        </center>
  
        <div className="flex flex-col md:flex-row gap-6 items-start justify-between mt-4">
          {/* Left Column */}
          <div className="max-h-screen overflow-y-scroll w-full md:w-1/2">
            {Array.isArray(data) &&
              data.map((item, index) => (
                <div
                  className="shadow-lg bg-gray-200 mt-6 rounded-lg p-4"
                  key={index}
                >
                  <button onClick={() => Read(item?.explains)}>
                    <center>
                      <h1 className="text-lg md:text-2xl text-gray-950 pt-3 pb-3">
                        {item.sub_title}
                      </h1>
                    </center>
                    <p className="text-justify p-2">{item.explains}</p>
                  </button>
                </div>
              ))}
          </div>
  
          {/* Right Column */}
          <div className="w-full md:w-1/2">
            <center>
              <textarea
                disabled
                className="p-4 bg-white shadow-lg border border-gray-800 rounded-lg w-full text-base md:text-xl h-40 md:h-60"
                value={read}
              ></textarea>
              <TextToSpeech
                text={`Hello I am Samuel, I will be reading to you ${read}`}
              />
            </center>
          </div>
        </div>
  
        {/* Instructions Section */}
        <div className="bg-white shadow-lg p-4 mt-8 rounded-lg">
          <h1 className="text-lg md:text-2xl font-bold">Instructions</h1>
          <ul className="text-left p-4 text-gray-600 list-disc text-base md:text-lg">
            <li>Do not refresh your page while taking the test.</li>
            <li>20 questions to be answered.</li>
            <li>
              Refreshing or navigation through a screen will result in failure (0
              marks recorded).
            </li>
            <li>
              If you encounter an error, use the appeal button on your dashboard
              to lodge a complaint.
            </li>
          </ul>
          <button
            onClick={() => {
              if (timeLeft > 0) {
                alert(`Spend ${timeLeft} seconds on this topic.`);
                return;
              }
              navigate("/test", {
                state: {
                  locationState: data?.[0].post,
                  id: data?.id,
                },
              });
            }}
            className="bg-blue-900 p-3 mt-4 rounded-lg text-white w-full text-lg md:text-2xl"
          >
            {timeLeft > 0 ? `Time Left: ${timeLeft}` : "Take Your Test"}
          </button>
        </div>
      </div>
    )}
  </div>
  
  );
};
