import React, { useState } from 'react';


const StatusIndicator = () => {
const [data, setData] = useState(null)
const [loading, setLoading] = useState(false)



  const FechTASK = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://researchgns.com.ng/fetch-tasks", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resdata = await response.json();
      if (!response.ok) {
        alert("check network connections");
        return;
      }
      setData(resdata.data);
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    FechTASK();
  }, []);



  return (
    <div className="flex flex-col  items-center justify-center h-screen bg-white  p-4">
      {loading?<center><h1>Looking for recent upload......</h1></center>:
      <h1 className="text-3xl font-bold mb-6">All post status</h1>
}
      <div className="flex flex-col items-center">
        {data?.map((item, index) => (
          <div key={index} className="flex items-center mb-4">
            {item.tags}
            <p className="text-lg ml-2">-- Week {item?.week}</p>
            <input type='checkbox' checked={item?.is_active} />
          </div>
        ))}
        
      </div>
    </div>
  );
};

export default StatusIndicator;
