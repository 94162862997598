import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";

export const AdminUpload = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const Upload = async (e) => {
    e.preventDefault();  // Prevent form submission

    setLoading(true);
    const formData = new FormData();
    const fileInput = document.getElementById("fileInput");

    if (fileInput.files.length === 0) {
      setMessage("Please select a file.");
      setLoading(false);
      return;
    }

    formData.append("file", fileInput.files[0]);

    try {
      const response = await fetch("https://researchgns.com.ng/create-risk", {
        method: "POST",
        body: formData,
      });
      const result = await response.json();

      if (response.ok) {
        setMessage(result.message);
      } else {
        setMessage(result.message || "An error occurred.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("Error uploading file.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={Upload}>
        <h2>Uploading the Procedure for Students</h2>
        <p>Please upload a (*pdf, *doc)</p>

        <div className="flex flex-col p-5 rounded-lg m-3 w-fit bg-white shadow-lg items-left">
          <input
            id="fileInput"
            className="bg-blue-950 hover:bg-blue-600 p-2 rounded-lg w-1/2 text-white text-center m-2"
            type="file"
            accept=".pdf, .doc, .docx, .xlsx"
          />
          <button
            type="submit"
            className="bg-blue-950 hover:bg-blue-600 p-2 rounded-lg w-1/2 text-white text-center m-2"
          >
            {loading ? (
              <FaSpinner className="animate-spin" />
            ) : (
              "Submit"
            )}
          </button>
          <h6
            style={{
              color: "red",
              fontSize: 14,
            }}
          >
            Please make sure you are submitting a valid document in reference to
            what the student has to learn.
          </h6>
        </div>

        <p className="text-center text-2xl p-2 text-blue-950">How does it work???</p>
        <p>
          The system will extract all the details from the document sent,
          which will be passed to the student and will be used as their test
          and more.
        </p>

        {message && (
          <p
            style={{
              textAlign: "center",
              color: message.startsWith("Error") ? "red" : "green",
              fontSize: 16,
            }}
          >
            {message}
          </p>
        )}
      </form>
    </div>
  );
};
