import React from "react";
import { FaInfoCircle } from "react-icons/fa";




export const Notfound =()=>{


   return(
      <div className =" flex flex-col  items-center justify-center h-screen bg-white  p-4 ">
         <FaInfoCircle className="text-9xl rounded-lg text-white bg-blue-950" />
         <h2 className="text-red-500 text-sm">
This page you are looking for does not exist
         </h2>
      </div>
   )
}