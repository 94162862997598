import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


export const AdminView =()=>{
   const location = useLocation();
     const navigate = useNavigate();
     const [loading, setLoading] = useState(false);
     const [data, setData] = useState([]);  // Use empty array to store the list of appeals
   
     // Fetch data function
     const Apeal = async () => {
       setLoading(true);
       try {
         const response = await fetch(`https://researchgns.com.ng/admin/get-complain?id=${location.state}`, {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             "Accept": "application/json",
           },
         });
   
         // Check if the response is OK before trying to parse it
         if (response.ok) {
           const result = await response.json();
           setData(result.data); // Set the response data to the state
     
         } else {
           
         }
       } catch (error) {
       
       } finally {
         setLoading(false);
       }
     };
   

     useEffect(() => {
       Apeal();
     }, []);
   

     const handleLogin =()=>{
      alert("contact admin")
      return
     }

   return(
      <div>
         <h2 className="text-blue-950 font-bold text-center p-3">Students Details</h2>
<p className="text-gray-950 text-center text-2xl">Name Cannot be display of matric Number {data[0]?.matric_number}</p>
            <h2 className="text-blue-950 text-center">Request: To reopen GNS PART {data[0]?.id} ({data[0]?.falculty}....)<span className="text-red-500 text-sm"> 5 more student apeal this also</span> </h2>
            <div className="shadow-lg p-3 m-3 bg-white"><p>
            <h2 className="text-blue-950 font-bold text-center p-3 "> Student Reason </h2>
        {data[0]?.reason}
         </p></div>
         <div className="flex flex-row items-center self-center content-center">
            <button onClick={handleLogin}  className="m-3 p-2 pr-4 pl-4  hover:bg-blue-500 bg-blue-950 rounded-lg text-white">
               Accept
            </button>
            <button onClick={handleLogin}  className="m-3 p-2 pr-4 pl-4  hover:bg-blue-500 bg-red-950 rounded-lg text-white">
               Decline
            </button>
         </div>
         <h3>You can take more Action by view Students Gns records <button className="text-blue-950 text-sm">Click here</button></h3>
      
      </div>
   )
}