import React from "react";

export default function RestOpen() {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6">Instructions</h1>
        
        <div className="space-y-4 text-lg text-gray-700">
          <p className="flex items-center">
            <span className="font-bold text-gray-800">1. </span> You can login with your matric number, and your matric number as the password.
          </p>
          <p className="flex items-center">
            <span className="font-bold text-gray-800">2. </span> In the class, you can either read or listen to the generated text.
          </p>
          <p className="flex items-center">
            <span className="font-bold text-gray-800">3. </span> Take your test and submit it. Please note that after the elapsed time, you will automatically be logged out of the test server and your test will be submitted. However, within the due time, you can still practice the same questions, but your results will not be saved anymore.
          </p>
          <p className="flex items-center">
            <span className="font-bold text-gray-800">4. </span> For more instructions, visit your webpage dashboard.
          </p>
        </div>
      </div>
    </div>
  );
}
